@import "src/styles/variables";

.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: absolute;
  z-index: 999;
  user-select: none;

  &__logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: 500;
    position: relative;
    user-select: none;
    user-focus: none;
    width: 70px;

    .logo__img {
      position: absolute;
      display: flex;
      object-fit: contain;
      width: auto;
      height: 140px;
    }

  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }

  &__nav {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 3rem 1rem 1rem;
    gap: 8rem;
  }

  &__link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $primary-font-color;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: .05em;
    transition: .5s;

    &:hover {
      color: $primary-hover-font-color;
    }
  }

  &__link-img {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 48px;


    .product-top, img {
      position: absolute;
      top: 0;
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__link-label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: .5rem;
  }

  .services {

    .service-1 {
      transform: rotate(0);
      transition: all .5s cubic-bezier(.5, 0, .15, 1.2);
    }

    .service-2 {
      transform: rotate(0);
      transition: all .8s cubic-bezier(.5, 0, .15, 1.2);
    }

    &:hover {
      .service-1 {
        transform: rotate(35deg);
      }

      .service-2 {
        transform: rotate(25deg);
      }
    }
  }


  .barbers {
    .barber-1, .barber-2 {
      rotate: 0;
      transition: all .5s cubic-bezier(.5, 0, .15, 2);
    }

    &:hover {
      .barber-1 {
        transform: rotate(15deg) translateY(-5px);
      }

      .barber-2 {
        transform: rotate(5deg) translateY(2px) translateX(-2px);
      }
    }
  }

  .products {
    .product-top {
      z-index: 1;
    }

    .product-top, .product-2 {
      rotate: 0;
      transition: all .5s cubic-bezier(.55, .02, .1, .9);
    }

    &:hover {
      .product-top {
        transform: rotate(-90deg);
      }

      .product-2 {
        transform: translateY(10px) translateX(-10px);
      }
    }
  }

  .contacts {
    .contact-1 {
      rotate: 0;
      transition: all .8s cubic-bezier(.5, 0, .15, 2);
    }

    .contact-2 {
      rotate: 0;
      transition: all .5s cubic-bezier(.5, 0, .15, 2);
    }

    &:hover {
      .contact-1 {
        transform: rotate(25deg);
      }

      .contact-2 {
        transform: rotate(7deg);
      }
    }
  }

}


@media screen and (width < 1200px) {
  .header {

    &__logo {
      order: -1;
      flex-basis: 100%;

      .logo__img {
        top: -55px;
        height: 100px;
      }
    }

    &__nav {
      flex-wrap: wrap;
      row-gap: 2rem;
      column-gap: 6rem;
      padding-top: 5rem;
    }
  }
}

@media screen and (width <= 768px) {
  .header {

    &__logo {

      .logo__img {
        height: 80px;
        top: -65px;
      }
    }

    &__nav {
      flex-wrap: wrap;
      row-gap: 1.5rem;
      column-gap: unset;
      justify-content: space-evenly;
    }

    &__link {
      font-size: 10px;
    }

    &__link-img {
      padding-top: 32px;


      .product-top, img {
        width: 32px;
        height: 32px;
      }
    }

  }
}
