@import "src/styles/variables";

.service-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: $background-inverse-color;
  color: $primary-inverse-font-color;

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 80%;
    padding: 5em 1em;
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4em;
    gap: 2em;
    &:before, &:after {
      content: "";
      display: flex;
      width: 4em;
      border-bottom: 1px solid $primary-inverse-font-color;
    }
    h2 {
      margin: 0;
      padding: 0;
      font-size: 1.6em;
      text-transform: uppercase;
      letter-spacing: .1em;
      text-align: center;

    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 1.5em;

    .service-category {
      display: flex;
      flex-direction: column;
      gap: .5em;

      &__title {
        padding: .5em 0;
        margin: 0;
        font-size: 1.1em;
        font-weight: 600;
        text-transform: uppercase;
      }
    }

    .service-item {
      padding: 0;
      margin: 0;
      font-weight: 500;
      display: flex;
      align-items: center;

      &__name {
        overflow: hidden;
        &:first-letter {
          text-transform: uppercase;
        }
      }

      &__amount {
        white-space: nowrap;
        text-align: end;
        min-width: 50px;
        align-self: end;
      }

      &__dotted-space {
        background-image: linear-gradient(to right, $primary-inverse-font-color 20%, rgba(255, 255, 255, 0) 0%);
        background-position: 2px 17px;
        background-size: 10px 2px;
        background-repeat: repeat-x;
        height: 25px;
        margin: 0 1em;
        display: flex;
        flex: 1;
        align-self: end;
        min-width: 25px;
      }
      &__price {
        font-weight: 600;
      }
      &__currency {
        font-weight: 600;
        padding-left: 4px;
      }
    }
  }
}

@media screen and (width < 1200px) {
  .service-block {
    font-size: .9rem;
    &__wrapper {
      width: 90%;
      padding: 5em 2em;
    }
  }
}

@media screen and (width <= 768px) {
  .service-block {
    font-size: .75rem;
    &__wrapper {
      width: 100%;
    }

    &__title {
      padding-bottom: 3em;
      gap: 2em;
      &:before, &:after {
        width: 3em;
      }
      h2 {
        font-size: 1.4em;
      }
    }

    &__list {
      gap: 1.5em;
      .service-item {
        &__dotted-space {
          background-position: 1px 12px;
          background-size: 5px 1px;
          height:15px;
        }
      }
    }
  }
}
