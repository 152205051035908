
$primary-font-color: #ececec;
$primary-hover-font-color: #a2a2a2;

$second-color: #d6ce92;

$background-color: #1C1A19;

$primary-inverse-font-color: #1d2221;
$primary-inverse-hover-font-color: #423e3e;

$background-inverse-color: #ececec;
