@import "src/styles/variables";

.contacts-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: $background-inverse-color;
  color: $primary-inverse-font-color;

  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 5em 0;
    width: 80%;
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4em;
    gap: 2em;

    &:before, &:after {
      content: "";
      display: flex;
      width: 4em;
      border-bottom: 1px solid $primary-inverse-font-color;
    }

    h2 {
      margin: 0;
      padding: 0;
      font-size: 1.6em;
      text-transform: uppercase;
      letter-spacing: .1em;
      text-align: center;

    }
  }

  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1em;

    .contact-item {
      display: flex;
      justify-content: space-between;
      width: 60%;
      gap: 1em;
      font-weight: 500;
      font-size: 1.1em;

      a {
        text-decoration: none;
        color: inherit;

        .icon {
          padding-right: .5em;
        }

        &:hover {
          color: $primary-hover-font-color;
        }
      }

      &__address {
        transition: .3s;
        cursor: pointer;
        &:hover {
          color: $primary-hover-font-color;
        }
      }
    }
  }

  &__social {
    display: flex;
    justify-content: center;
    gap: 5em;
    padding-top: 4rem;

    .icon {
      padding-right: .5em;
    }

    a {
      text-decoration: none;
      padding: 2px 8px;
      color: inherit;
      transition: 200ms;
      position: relative;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      font-weight: 600;

      &:after {
        display: flex;
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 500px;
        background-color: $second-color;
        position: absolute;
        bottom: -12px;
        transition: all .5s cubic-bezier(.5, 0, .15, 1.2);
      }

      &:hover {
        color: $primary-hover-font-color;
        &:after {
          width: 100%;
        }
      }
    }
  }

  .map {
    display: block;
    width: 100%;
    height: 700px;
    max-height: 70vh;

    .leaflet-container {
      width: 100%;
      height: 100%;
      z-index: 1;
      border: none;
      outline: none;

      &:before {
        content: "";
        top: 0;
        width: 100%;
        left: 0;
        right: 0;
        position: absolute;
        z-index: 999;
        box-shadow: 0 0 1.5em 1.5em $background-inverse-color;
      }
    }

    .popup-container {
      display: flex;
      align-items: center;
      gap: 1em;
      width: 100%;

      &__info {
        display: flex;
        flex-direction: column;
        p {
          margin: 0;
          padding: 0;
          color: $primary-inverse-font-color;
        }
      }

      &__address {
        font-weight: 500;
      }

      &__phone {
        white-space: nowrap;
        line-height: 2em;
        text-decoration: none;
        color: inherit;
        .icon {
          padding-right: .5em;
          color: $primary-hover-font-color;
        }
      }

      &__link {
        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          text-decoration: none;
          color: inherit;
          white-space: nowrap;
          font-size: .6em;
          font-weight: 600;
        }
        img {
          width: 36px;
          height: 36px;
        }
      }
    }
  }

}

@media screen and (width < 1200px) {
  .contacts-block {
    font-size: .9rem;

    &__wrapper {
      display: flex;
      flex-direction: column;
      padding: 5em 0;
      width: 90%;
    }

    &__list {

      .contact-item {
        display: flex;
        justify-content: space-between;
        width: 75%;
        gap: 1em;
        font-weight: 500;
        font-size: 1.1em;

        a {
          text-decoration: none;
          color: inherit;

          .icon {
            padding-right: .5em;
          }

          &:hover {
            color: $primary-hover-font-color;
          }
        }

        &__address {
          transition: .3s;
          cursor: pointer;
          &:hover {
            color: $primary-hover-font-color;
          }
        }
      }
    }

    &__social {
      display: flex;
      justify-content: center;
      gap: 5em;
      padding-top: 4rem;
      a {
        text-decoration: none;
        padding: 2px 8px;
        color: inherit;
        transition: 200ms;
        position: relative;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        font-weight: 600;

        &:after {
          display: flex;
          content: "";
          width: 6px;
          height: 6px;
          border-radius: 500px;
          background-color: $second-color;
          position: absolute;
          bottom: -12px;
          transition: all .5s cubic-bezier(.5, 0, .15, 1.2);
        }

        &:hover {
          color: $primary-hover-font-color;
          &:after {
            width: 100%;
          }
        }
      }
    }

    .map {
      display: block;
      width: 100%;
      height: 700px;
      max-height: 70vh;

      .popup-container {
        display: flex;
        align-items: center;
        gap: 1em;
        width: 100%;

        &__info {
          display: flex;
          flex-direction: column;
          p {
            margin: 0;
            padding: 0;
            color: $primary-inverse-font-color;
          }
        }

        &__address {
          font-weight: 500;
        }

        &__phone {
          white-space: nowrap;
          line-height: 2em;
          text-decoration: none;
          color: inherit;
          .icon {
            padding-right: .5em;
            color: $primary-hover-font-color;
          }
        }

        &__link {
          a {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            text-decoration: none;
            color: inherit;
            white-space: nowrap;
            font-size: .7em;
            font-weight: 600;
          }
          img {
            width: 36px;
            height: 36px;
          }
        }
      }
    }

  }

}

@media screen and (width <= 768px) {
  .contacts-block {
    font-size: .75rem;

    &__wrapper {
      display: flex;
      flex-direction: column;
      padding: 5em 0;
      width: 100%;
    }

    &__list {

      gap: 2em;
      .contact-item {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 2em;
        flex-direction: column;
        gap: .5em;
        font-weight: 500;
        font-size: 1.1em;

        a {
          text-decoration: none;
          color: inherit;

          .icon {
            padding-right: .5em;
          }

          &:hover {
            color: $primary-hover-font-color;
          }
        }

        &__address {
          transition: .3s;
          cursor: pointer;
          &:hover {
            color: $primary-hover-font-color;
          }
        }
      }
    }

    &__social {
      display: flex;
      justify-content: center;
      gap: 1.5em;
      padding-top: 3rem;

      .icon {
        font-size: 1.4rem;
        padding: 0;
      }

      .text {
        display: none;
      }

      a {

        &:after {
          display: none;
        }
      }
    }

    .map {
      display: block;
      width: 100%;
      height: 700px;
      max-height: 70vh;

      .popup-container {
        display: flex;
        align-items: center;
        font-size: .7rem;
        gap: 1em;
        width: max-content;

        &__info {
          display: flex;
          flex-direction: column;
          p {
            margin: 0;
            padding: 0;
            color: $primary-inverse-font-color;
          }
        }

        &__address {
          font-weight: 500;
        }

        &__phone {
          white-space: nowrap;
          line-height: 2em;
          text-decoration: none;
          color: inherit;
          .icon {
            padding-right: .5em;
            color: $primary-hover-font-color;
          }
        }

        &__link {
          a {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            text-decoration: none;
            color: inherit;
            white-space: nowrap;
            font-size: .8em;
            font-weight: 600;
          }
          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

  }
}