@import "/src/styles/variables";

.welcome-block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  min-height: 600px;
  position: relative;
  user-select: none;

  &__background {
    padding-top: 6rem;
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 100%;

    &-img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 80%;
      position: relative;

      &:after, &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
      }

      &:after {
        bottom: 0;
        background: linear-gradient(to top, transparent 60%, $background-color 100%);
      }

      &:before {
        top: 0;
        z-index: 1;
        background: linear-gradient(to bottom, transparent 60%, $background-color 100%);
      }

      img {
        width: 100%;
        object-fit: cover;
        overflow: hidden;
        z-index: 0;
        height: 100%;
        display: block;
        filter: grayscale(40%);
      }
    }


  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 80%;
    align-items: center;
    justify-content: flex-end;
    z-index: 1;
    padding: 48px;
  }

  &__bottom {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    width: 100%;
  }

  &__slogan {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    font-weight: 200;
  }

  &__sign-up {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    a {
      color: $primary-font-color;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: .2em;
      text-decoration: none;
      border: 4px solid $second-color;
      padding: .7em 2em;
      transition: all .5s cubic-bezier(.5, 0, .15, 1.6);
      position: relative;
      overflow: hidden;

      &:before, &:after {
        position: absolute;
        left: 0;
        z-index: 0;
        display: flex;
        width: 100%;
        height: 0;
        background-color: rgba($second-color, .1);
        content: "";
        transition: all .8s cubic-bezier(.5, 0, .15, 1.2);
      }

      &:before {
        top: 50%;
      }

      &:after {
        bottom: 50%;
      }

      &:hover {
        padding: .7em 3em;
        &:before, &:after {
          height: 100%;
        }
      }
    }
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 1em;
    text-align: end;
    font-weight: 500;

    &-addresses, &-phones {
      display: flex;
      flex-direction: column;
      gap: .5em;
    }

    &-social {
      display: flex;
      gap: 1em;
      padding-top: 2em;

      .icon {
        display: none;
      }

      a {
        text-decoration: none;
        padding: 2px 8px;
        color: $primary-font-color;
        transition: 200ms;
        position: relative;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;

        &:after {
          display: flex;
          content: "";
          width: 6px;
          height: 6px;
          border-radius: 500px;
          background-color: $second-color;
          position: absolute;
          bottom: -12px;
          transition: all .5s cubic-bezier(.5, 0, .15, 1.2);
        }

        &:hover {
          color: $primary-hover-font-color;
          &:after {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (width < 1200px) {
  .welcome-block {

    &__background {
      padding-top: 10rem;
      width: 90%;
    }

    &__container {
      width: 90%;
      padding: 36px;
    }

    &__slogan {
      font-size: .8rem;
    }

    &__sign-up {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      a {
        font-size: .85rem;
        border: 3px solid $second-color;
      }
    }

    &__contacts {
      font-size: .9rem;

      &-social {
        font-size: 1.4rem;
        gap: .8em;

        .icon {
          display: inline-block;
        }

        .text {
          display: none;
        }

        a {
          padding: 2px 6px;


          &:after {
            width: 4px;
            height: 4px;
            bottom: -10px;
          }

        }
      }
    }
  }
}

@media screen and (width <= 768px) {
  .welcome-block {

    &__background {
      padding-top: 10rem;
      width: 100%;
    }

    &__container {
      width: 100%;
      padding: 24px;
    }

    &__bottom {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 3rem;
    }

    &__slogan {
      font-size: .75rem;
      font-weight: 400;
      order: 999;
      text-align: center;
      align-items: center;
    }

    &__sign-up {
      a {
        font-size: .7rem;
        border: 3px solid $second-color;
      }
    }

    &__contacts {
      font-size: .8rem;
      align-items: center;
      text-align: center;
      box-shadow: inset 100px 100px 100px 100px rgba(0, 0, 0, .3),
      0 0 100px 100px rgba(0, 0, 0, .3);

      &-social {
        font-size: 1.4em;
        gap: .8em;

        .icon {
          display: inline-block;
        }

        .text {
          display: none;
        }

        a {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
