@import "src/styles/variables";

.barbers-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 80%;
    padding: 5em 1em;
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4em;
    gap: 2em;

    &:before, &:after {
      content: "";
      display: flex;
      width: 4em;
      border-bottom: 1px solid $primary-font-color;
    }

    h2 {
      margin: 0;
      padding: 0;
      font-size: 1.6em;
      text-transform: uppercase;
      letter-spacing: .1em;
      text-align: center;

    }
  }

  &__list {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 6em;
    flex-wrap: wrap;
    font-size: .9em;
    text-align: center;
    font-weight: 600;

    .barber-item {
      max-width: 220px;
      min-width: 220px;
      cursor: default;
      user-select: none;
      user-focus: none;
      position: relative;
      transition: .5s cubic-bezier(.5, 0, .1, 1.2);
      display: flex;
      flex-direction: column;
      align-items: center;


      &__img {
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        height: 200px;
        border: 3px solid $second-color;
        border-radius: 500px;
        position: relative;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;

        }
      }

      &__name {
        z-index: 2;
      }

      &:hover {
        transform: scale(1.1);

      }
    }
  }

}

@media screen and (width < 1200px) {
  .barbers-block {
    font-size: .9rem;

    &__wrapper {
      width: 90%;
    }

    &__list {
      gap: 5em;

      .barber-item {
        max-width: 170px;
        min-width: 170px;

        &__img {
          width: 150px;
          height: 150px;
        }
      }
    }
  }
}

@media screen and (width <= 768px) {
  .barbers-block {
    font-size: .75rem;

    &__wrapper {
      width: 100%;
    }

    &__title {
      padding-bottom: 3em;
      gap: 2em;
      &:before, &:after {
        width: 3em;
      }
      h2 {
        font-size: 1.4em;
      }
    }

    &__list {
      gap: 4em;

      .barber-item {
        max-width: 120px;
        min-width: 120px;

        &__img {
          width: 100px;
          height: 100px;
          border: 2px solid $second-color;
        }
      }
    }
  }
}