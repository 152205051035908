@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;600&display=swap');
@import "./styles/variables";
@import "leaflet/dist/leaflet.css";

* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

html, body {
  background: $background-color;
  color: $primary-font-color;
  padding: 0;
  margin: 0;
}